import React from "react";
import styled from "styled-components";
import Layout from "../components/Layout";
import Seo from "../components/SEO";
import ContentWrapper from "../components/ContentWrapper";
import { graphql, Link } from "gatsby";

const MainWrapper = styled.div`
  padding-top: 65px;
  @media screen and (max-width: ${props => props.theme.responsive.large}) {
    padding-top: 63px;
  }
  .breadcrumbs {
    background: #ececec;
    padding: 19px 0;
    min-height: 47px;
  }
  .breadcrumbs-list {
    font-size: 14px;
    line-height: 1;
  }
  .breadcrumbs-item {
    float: left;
    margin: -2px 6px 0 0;
    &:last-child {
      &::before {
        content: ">";
        padding: 0 5px 0 0;
      }
    }
  }
  .breadcrumbs-item-link {
    color: #555;
    &:hover {
      text-decoration: underline;
    }
  }
  .news-list {
    margin: 30px 0;
    @media screen and (max-width: ${props => props.theme.responsive.large}) {
      margin: 15px 0;
    }
  }
  .news-list-item {
    &:nth-child(odd) {
      background: #f6f6f9;
    }
  }
  .news-link {
    display: block;
    padding: 15px 145px 15px 30px;
    min-height: 110px;
    text-align: left;
    transition: opacity 0.2s;
    position: relative;
    @media screen and (max-width: ${props => props.theme.responsive.large}) {
      padding: 10px 55px 15px 20px;
    }
    &:after {
      content: "";
      position: absolute;
      top: 0;
      bottom: 0;
      margin: auto;
      right: 44px;
      width: 8px;
      height: 8px;
      border-top: 3px solid #818181;
      border-right: 3px solid #818181;
      transform: rotate(45deg);
      @media screen and (max-width: ${props => props.theme.responsive.large}) {
        right: 23px;
        width: 6px;
        height: 6px;
        border-top: 2px solid #818181;
        border-right: 2px solid #818181;
      }
    }
    &:hover {
      text-decoration: none;
      opacity: 0.7;
    }
  }
  .news-link-date {
    font-size: 13px;
    color: #5b5b5b;
    @media screen and (max-width: ${props => props.theme.responsive.large}) {
      display: block;
      font-size: 12px;
    }
  }
  .news-link-text {
    font-size: 14px;
    color: #000;
    margin-top: 2px;
    @media screen and (max-width: ${props => props.theme.responsive.large}) {
      font-size: 12px;
    }
  }
`;

class NewsPage extends React.Component {
  render() {
    const { data } = this.props;
    const posts = data.allMicrocmsNews.edges;

    return (
      <Layout>
        <Seo title="ニュース" description="株式会社八木造園土木のニュースページです。" />
        <MainWrapper>
          <div className="breadcrumbs">
            <ContentWrapper>
              <ol className="breadcrumbs-list">
                <li className="breadcrumbs-item">
                  <Link to={`/`} className="breadcrumbs-item-link">ホーム</Link>
                </li>
                <li className="breadcrumbs-item">
                  <Link to={`/news`} className="breadcrumbs-item-link">ニュース</Link>
                </li>
              </ol>
            </ContentWrapper>
          </div>
          <ContentWrapper>
            <ul className="news-list">
              {posts.map(({ node }) => {
                return (
                  <li key={node.newsId} className="news-list-item">
                    <Link to={`/news/${node.newsId}`} className="news-link">
                      <time className="news-link-date">{node.createdAt}</time>
                      <p className="news-link-text">{node.title}</p>
                    </Link>
                  </li>
                );
              })}
            </ul>
          </ContentWrapper>
        </MainWrapper>
      </Layout>
    );
  };
};

export default NewsPage;

export const pageQuery = graphql`
  query {
    allMicrocmsNews(sort: {order: DESC, fields: createdAt}) {
      edges {
        node {
          createdAt(formatString: "YYYY年MM月DD日", locale: "ja-JP")
          newsId
          title
          body
        }
      }
    }
  }  
`;